<template>
  <div>
    <v-container fluid>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        color="blue accent-4"
      ></v-progress-linear>
      <v-snackbar v-model="snackbar" :color="snackbar_color" rounded="pill" top>
        {{ text }}

        <template v-slot:action="{ attrs }">
          <v-btn color="white" icon v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-row justify="center" align-sm="center" class="mt-2">
        <v-col sm cols="12" align-self="center" class="d-flex justify-center">
          <v-img
            alt="100 Miles Logo"
            class="mr-2 shrink"
            contain
            src="../../assets/login-page-logo.png"
            transition="scale-transition"
            max-height="200"
            max-width="200"
          />
        </v-col>
        <v-col
          sm
          cols="12"
          align-self="center"
          class="
            d-flex
            justify-center
            rounded-t-xl
            grey
            lighten-5
            flex-column
            pa-6
          "
        >
          <span class="primary--text mb-2 title">FORGOT PASSWORD</span>
          <v-divider light></v-divider>
          <p class="mt-2">Enter your email address for reset passowrd link.</p>
          <v-form
            v-on:submit.prevent="forgotpassword"
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-text-field
              v-model="email"
              :rules="emailRules"
              label="E-mail"
              prepend-inner-icon="fas fa-envelope"
              class="mb-2 mt-4"
              rounded
              outlined
              color="primary"
            />

            <v-btn
              color="info"
              type="submit"
              rounded
              block
              @click="validate"
              :disabled="!valid"
            >
              send Reset Password link
            </v-btn>
          </v-form>
        </v-col>
        <v-col
          sm
          cols="12"
          align-self="center"
          class="
            d-flex
            justify-center
            align-center
            rounded-b-xl
            grey
            lighten-5
            flex-column
          "
        >
          <div>
            already have an account?
            <router-link to="/auth/login" class="routerlink"
              >Sign In</router-link
            >
            .
          </div>
          <div>
            Don't Have an Account ?
            <router-link to="/auth/register" class="routerlink"
              >Create Account</router-link
            >
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Authentication from "../../api/Authentication";
export default {
  name: "forgot password",
  data() {
    return {
      email: "",
      loading: false,
      snackbar: false,
      snackbar_color: "",
      text: "",
      valid: true,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid.",
      ],
    };
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },

    forgotpassword: function () {
      console.log("forgot password function executed..");
      this.loading = true;
      Authentication.forgotPassword(this.email)
        .then((response) => {
          //console.log('response data :',response.data);
          this.loading = false;
          if (response.status == 200) {
            this.loading = false;
            this.snackbar = true;
            this.snackbar_color = "success";
            this.text = response.data.success;
          }
        })
        .catch((error) => {
          if (error.response) {
            this.loading = false;
            this.snackbar = true;
            this.snackbar_color = "error";
            this.text = error.response.data;
            //console.log('error Response :',error.response);
          } else if (error.request) {
            this.loading = false;
            this.snackbar = true;
            this.snackbar_color = "error";
            this.text = error.request;
          } else {
            this.loading = false;
            this.snackbar = true;
            this.snackbar_color = "error";
            this.text = error.message;
          }
        });
    },
  },
};
</script>
